<template>
  <div class="row plain-element">
  <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-end">
              <div class="row plain-element text-start">
                <div class=" col col-12 col-sm-12 col-md-6 col-lg-6 plain-element text-start">
                    <h6>Company: </h6>
                    <p>{{ divisionPlanData.company }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Plan Manager: </h6>
                <p>
                  {{ divisionPlanData.plan_manager }}
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Created: </h6>
                <p>
                  {{ divisionPlanData.plan_dates[0] }}
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Updated: </h6>
                <p>
                  {{ divisionPlanData.plan_dates[1] }}
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Description: </h6>
                <p>
                  {{ divisionPlanData.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Energy Engineers: </h6>
              <div class="row plain-element text-start">
                <div class=" col col-12 col-sm-12 col-md-5 col-lg-5 plain-element text-start">
                    <p class="margin-bottom-0" v-for="(engineer, index) in divisionPlanData.opportunity_plan_relations.energy_engineers.slice(0, 6)" :key="index">
                        {{engineer}}
                    </p>
                </div>
                <div class=" col col-12 col-sm-12 col-md-5 col-lg-5 plain-element text-start">
                    <p class="margin-bottom-0" v-for="(engineer, index) in divisionPlanData.opportunity_plan_relations.energy_engineers.slice(6, 12)" :key="index">
                        {{engineer}}
                    </p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col col-12 col-sm-12 col-md-4 col-lg-4 plain-element text-start">
            <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Total Investment: </h6>
                <p v-if="divisionPlanData.currency_details[1] === '$'">${{Math.round((divisionPlanData.opportunity_plan_relations.total_investment)).toLocaleString()}}</p>
                <p v-else>{{Math.round((divisionPlanData.opportunity_plan_relations.total_investment)).toLocaleString()}}{{divisionPlanData.currency_details[1]}}</p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Cost Baseline: </h6>
                <p v-if="divisionPlanData.currency_details[1] === '$'">${{Math.round((divisionPlanData.division_site_plan_relations.total_cost_baseline)).toLocaleString()}}</p>
                <p v-else>{{Math.round((divisionPlanData.division_site_plan_relations.total_cost_baseline)).toLocaleString()}}{{divisionPlanData.currency_details[1]}}</p>
              </div>
            </div>
            <div  v-if="divisionPlanData.currency !== 'EUR'" class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>EUR{{divisionPlanData.currency}} Exchange Rate: </h6>
                <p>
                  {{ divisionPlanData.currency_details[0] }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element text-end">
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Annual CO2 Emission: </h6>
                <p>
                  {{Math.round(divisionPlanData.division_site_plan_relations.total_co2_emission_tonnes).toLocaleString() }} Tonnes
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Annual Electricity CO2 Emission: </h6>
                <p>
                  {{Math.round(divisionPlanData.division_site_plan_relations.total_co2_electricity_tonnes_previous_year).toLocaleString() }} Tonnes
                </p>
              </div>
            </div>
          </div>
          <div class="row plain-element text-start">
            <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start"><h6>Annual Fuel CO2 Emission: </h6>
                <p>
                  {{Math.round(divisionPlanData.division_site_plan_relations.total_co2_fuel_tonnes_previous_year).toLocaleString() }} Tonnes
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class=" col col-12 col-sm-12 col-md-2 col-lg-2 plain-element text-end">
           <div v-if="divisionPlanData.plan_manager ===  getUsername()" class=" col col-12 col-sm-12 col-md-10 col-lg-10 plain-element text-end">
              <div class="row plain-element text-start">
                <h6>Additional Options: </h6>
              </div>
              <div class="row plain-element text-start">
                <div class=" col col-12 col-sm-12 col-md-10 col-lg-10 text-start margin-top-5">
                  <router-link :to="{name: 'division-plan-delete', params: {id: divisionPlanData.id}}" class="btn btn-delete">
                    Delete Plan
                  </router-link>
                </div>
              </div>
            </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";


export default {
  name: "DivisionPlanDetailsComponent",
  props: {
    divisionPlanData: {
      type: Object,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    ...mapGetters(["getUsername"]),

    calculateTotalInvestmentSum(opportunitiesObject) {
      let totalSum = 0;

      // Iterate through the opportunities object
      for (const opportunityKey in opportunitiesObject) {
        if (Object.prototype.hasOwnProperty.call(opportunitiesObject, opportunityKey)) {
          const opportunity = opportunitiesObject[opportunityKey];
          const investment = opportunity.opportunity__total_investment_eur || 0;

          // Add the investment value to the total sum
          totalSum += investment;
        }
      }

      return totalSum;
    },
  },

  computed: {

  },
  created() {


  },
  beforeRouteUpdate (to, from, next) {

    next();
  }

}
</script>
