<template>

<div class="row plain-element">
  <div v-if="!isLoggedIn()" class="dashboard-cards">
    <NoPermissionComponent/>
  </div>
  <div v-if="getUsername()" class="dashboard">
    <div class="row plain-element top-menu">
      <ul>
        <li>
          <router-link :to="{name: 'home'}" class="horizontal-link">Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'site-plan-search'}" class="horizontal-link">Site Plans
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'site-plan-create'}" class="horizontal-link">Create Site Plan
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'site-plan-archive-search'}" class="horizontal-link underline-green">Archive</router-link>
        </li>
      </ul>
    </div>

    <div v-if="getPlansSites()" class="row plain-element">
      <div class="row row-opportunities">
        <div class="row plain-element">
          <div  class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
            <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
              <div class="row plain-element text-start">
                <label class="label-search">Site</label>
              </div>
              <select v-model="site_code" name="site_code" id="site_code" @change="clearSearch()">
                <option value="" selected> All Sites</option>
                <option v-for="(element, index) in getPlansSites()" :key="index" :value="element">
                  {{ getSiteNames()[element] }}
                </option>
              </select>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
          </div>
          <div class="col col-12 col-sm-12 col-md-1 col-lg-2 plain-element text-end">
          </div>
          <div class=" col col-12 col-sm-12 col-md-5 col-lg-4 plain-element text-end">
            <div v-if="getPlansCompanies()" class="input-field  col col-12 col-sm-12 col-form">
              <div class="row searchbox-wrapper">
                <div class="row plain-element text-start">
                  <label class="label-search">Table Filter</label>
                </div>
                <input class="form-control" id="keyWordSearchbox" type="text" placeholder="e.g. Steam"
                       aria-label="e.g. Steam" v-model="search">
              </div>
            </div>
          </div>
          <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
          </div>
          <div v-if="getArchivedSitePlans()  && getLoader() === false" class="row plain-element">
            <div class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
              <h6> {{ keywordFilteredList.length }} Results</h6>
            </div>
            <div class="col col-sm-6 col-md-6 col-lg-6 plain-element">
            </div>
          </div>
          <p class="w-100"></p>
          <div id="parent">
            <div id="child">
              <table v-if="getArchivedSitePlans() && getLoader() === false"  id="searchTable">
                <thead v-if="keywordFilteredList.length < 10000 && keywordFilteredList.length > 0">
                <tr>
                  <th class="text-start">

                  </th>
                  <th class="text-start" @click="sortTableNew(1)">Site
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                  <th class="text-start" @click="sortTableNew(2)">Name
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>

                  <th class="text-start th-description">Description</th>
                  <th class="text-start" @click="sortTableNew(4)">Plan Manager
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                  <th class="text-start th-description">Rev.</th>
                </tr>
                </thead>
                <thead v-else-if="keywordFilteredList.length > 10000">
                <tr>
                  <th class="text-start"></th>
                  <th class="text-start">Site</th>
                  <th class="text-start">Name</th>
                  <th class="text-start th-description">Description</th>
                  <th class="text-start th-description">Plan Manager</th>
                  <th class="text-start th-description">Rev.</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="plan in keywordFilteredList" :key="plan.id" @click="goToPlanView(plan.id.toString())">
                  <td class="text-start field-plans-number">
                    <img alt="img" v-if="plan.plan_manager.includes('em3')"
                    :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + 'EM3.png'"
                    class="img responsive img-icon" id="img-icon-18">
                    <img alt="img" v-else
                    :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() +'.png'"
                    class="img responsive img-icon" id="img-icon-18">
                  </td>
                  <td class="text-start field-plans-text">{{getSiteCompanyNames()[plan.site_code][0]}}</td>
                  <td class="text-start field-plans-description">{{plan.name}}</td>
                  <td class="text-start field-plans-description">{{truncatechars(plan.description, 240) }}</td>
                  <td class="text-start field-plans-description">{{ plan.plan_manager }}</td>
                  <td class="text-start field-plans-description">{{ plan.revision }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="getArchivedSitePlans().length > 0" class="border-line"></div>
            </div>
          </div>
          <div v-if="resultCount && keywordFilteredList.length > 200" class="row plain-element">
            <button v-show="next" @click="submitQuery" class="btn btn-loading">
              Load More
            </button>
          </div>
          <div v-if="resultCount && keywordFilteredList.length === 0" class="row plain-element">
            <h6>No results match search criteria</h6>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import { sortTableNew } from "@/common/tables.js";
import { truncatechars } from "@/common/general.js";
import { mapGetters, mapActions } from "vuex";

import NoPermissionComponent from "@/components/NoPermissionComponent.vue"

export default {
  name: "SitePlanArchiveStandardSearch",
  components: {
      NoPermissionComponent,
  },
  data() {
    return {
      error: null,
      resultCount: null,
      search: "",
      active: "plans",
      siteList: null,
      searchQuery: null,
      site_code: "",
    }
  },

  methods: {
    ...mapGetters(["getFormError","getNext","getFormError", "getResultCount", "getLoader", "getUsername", "isLoggedIn",
                  "getPlansCompanies", "getArchivedSitePlans", "getPlansSites", "getCompanyString", "getSiteNames", "getSiteCompanyNames"]),
    ...mapActions(["fetchPlansQueryset", "clearPlanList", "performSetFormError","fetchAllSitePlans","fetchPlansCompanies", "fetchPlansSites", "fetchSiteNames"]),

    sortTableNew: sortTableNew,
    getFullPlanList() {
      this.fetchAllSitePlans();
    },
    clearSeu() {
        if (this.site_code === "") {
          this.search = ""
        } else {
          this.search = ""
        }
    },
    clearSearch() {
       this.search = "";
    },
    callback: function (ins) {
        ins.update(ins.endVal + 100)
    },
    goToPlanView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'site-plan-view', params: { id: id, tab: 'plan-details'} });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'site-plan-view', params: { id: id, tab: 'plan-details'} });
        window.open(routeData.href, '_blank');
      }
    },
    truncatechars: truncatechars,
  },
  computed: {
    filteredList() {
        if (this.site_code !== "" ) {
            return this.getArchivedSitePlans().filter(plan => {
              return plan.site_code.toLowerCase() === this.site_code.toLowerCase()
            })
        }
        else {
          return this.getArchivedSitePlans()
        }
    },
    keywordFilteredList() {
      return this.filteredList.filter(plan => {
        return plan.site_code.toLowerCase().includes(this.search.toLowerCase()) ||
               plan.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    //  Just in case if some strings would be too long and would destroy a layout


  },
  created() {
    this.fetchPlansCompanies();
    this.performSetFormError(null);
    this.getFullPlanList();
    this.fetchPlansSites();
    this.fetchSiteNames();
    document.title = "Site Plan Search";
    window.scrollTo(0,0);
  },
  mounted() {

  },
  beforeCreated() {

  }
}
</script>
