<template>
<div class="row plain-element">
<div v-if="site_plans.length > 0" class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
            <p class="w-100"></p>
            <div class="row plain-element">
              <div  class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
                <h6 class="font-cyan-blue font-12"> {{ site_plans.length }} Plans</h6>
              </div>
            </div>
            <table id="searchPlanTable">
              <thead>
                <tr>
                  <th class="text-start" @click="sortPlanTable(0)"><span class="margin-left-50">Site</span>
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                  <th class="text-start" @click="sortPlanTable(1)">Name
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>

                  <th class="text-start th-description">Description</th>
                  <th class="text-start" @click="sortPlanTable(3)">Plan Manager
                    <span class="fa fa-stack">
                                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                                  <i class="fa fa-caret-up" aria-hidden="true"></i>
                              </span>
                  </th>
                  <th class="text-center" @click="sortPlanTableCurrencyInt(4)">
                    <span>Cost Baseline( {{currency_details[1]}})</span>
                    <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th class="text-center" @click="sortPlanTableCurrencyInt(5)">Co2 Total Emission(T)
                    <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th class="text-center" @click="sortPlanTableCurrencyInt(6)">Co2 Electricity(T)
                    <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                  </th>
                  <th class="text-center" @click="sortPlanTableCurrencyInt(7)">Co2 Fuel(T)
                    <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="plan in site_plans" :key=plan.name @click="goToSitePlanView(plan.site_plan__id)">
                  <td class="text-start field-plans-number">{{plan.site_plan__site_code}} - {{siteNames[plan.site_plan__site_code]}}</td>
                  <td class="text-start field-plans-text">{{plan.site_plan__name}}</td>
                  <td class="text-start field-plans-description">{{truncatechars(plan.site_plan__description, 240) }}</td>
                  <td class="text-start field-plans-text">{{ plan.site_plan__plan_manager }}</td>
                  <td class="text-center field-text">
                    <span v-if="currency_details[1] === '$'">${{Math.round((plan.site_plan__cost_baseline)).toLocaleString()}}</span>
                    <span v-else>{{Math.round(plan.site_plan__cost_baseline).toLocaleString()}}{{currency_details[1]}}</span>
                  </td>
                  <td class="text-center field-plans-text">{{ Math.round(plan.site_plan__co2_emission_tonnes).toLocaleString() }}</td>
                  <td class="text-center field-plans-text">{{ Math.round(plan.site_plan__co2_electricity_tonnes_previous_year).toLocaleString() }}</td>
                  <td class="text-center field-plans-text">{{ Math.round(plan.site_plan__co2_fuel_tonnes_previous_year).toLocaleString() }}</td>
                </tr>
              </tbody>


            </table>
          </div>
        </div>
      </div>
</template>

<script>

import { truncatechars } from "@/common/general.js";
import {  sortPlanTable, sortPlanTableCurrencyInt } from "@/common/tables.js";

export default {
  name: "CorporatePlanSitePlansComponent",
  props: {
    site_plans: {
      type: Array,
      required: true,
    },
    siteNames: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    currency_details: {
      type: Array,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  components: {

  },
  data() {
    return {
      climateActionLegend: {
        "1 - Low/No Cost": "#ff9d9a",
        "2 - Energy Efficiency": "#86bcb6",
        "3 - Renewable Electricity": "#f1ce63",
        "4 - Electrification of Heat": "#a0cbe8",
        "5 - Renewable Fuels": "#d4a6c8",
      },
    }
  },
  methods: {
    truncatechars: truncatechars,
    sortPlanTable: sortPlanTable,
    sortPlanTableCurrencyInt: sortPlanTableCurrencyInt,
    goToSitePlanView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'site-plan-view', params: { id: id, tab: "plan-details" } });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'site-plan-view', params: { id: id, tab: "plan-details" } });
        window.open(routeData.href, '_blank');
      }
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
