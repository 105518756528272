<template>
<div class="row plain-element">
  <p class="error"  v-if="getFormError()">
    <span id="formError">{{ getFormError() }}</span>
  </p>
  <p class="error"  v-else>
    <span id="formError"></span>
  </p>
  <div v-if="getOpportunities()" class="row plain-element">
    <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
      <div class="row row-functions text-start">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1 plain-element">
          <img alt="img" :src="'https://saperapublicstorage.blob.core.windows.net/media/logos/' + getCompanyString() + '.png'"
               class="img responsive img-icon">
        </div>
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-8 plain-element text-start">
          <h3> Schedule Opportunities for {{ name }}</h3>
        </div>
        <div  v-if="getLoader() === false" class=" col col-12 col-sm-12 col-md-12 col-lg-3 plain-element col-buttons">

          <button type="submit" class="btn btn-report" @click="updatePlanDates()">
            Submit Changes
          </button>
        </div>
      </div>
    </div>
  </div>
    <div class="row plain-element">
        <div class="row plain-element">
          <div v-if="getLoader() === true" class="row">
            <div class="loader" id="loader"></div>
          </div>
          <div v-if="getOpportunities()  && getLoader() === false" class="row plain-element">
            <div v-if="getResultCount()" class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
              <h6 class="font-cyan-blue font-12"> {{ keywordFilteredList.length }} Opportunities</h6>
            </div>
            <div class="col col-sm-9 col-md-9 col-lg-9 plain-element text-end">
              <a class="btn btn-reorder" hidden><i class="fa-solid fa-chart-column"></i> Update Graphs</a>
            </div>
          </div>
          <p class="w-100"></p>
              <table v-if="getOpportunities() && getLoader() === false"  id="searchTable">
                <thead v-if="keywordFilteredList.length > 0">
                <tr>
                  <th  class="text-start">Opportunity No.</th>
                  <th  class="text-start">SEU</th>
                  <th  class="text-start">Climate Action Phase</th>
                  <th class="text-center th-description">Description</th>
                  <th class="text-end">
                      Savings
                  </th>
                  <th class="text-end">
                      Total Inv.
                  </th>
                  <th class="text-end">CO2</th>
                  <th class="text-center th-description">Start Date</th>
                  <th class="text-center th-description">End Date</th>
                </tr>
                </thead>
                <tbody>

                  <tr v-for="(opportunity, index) in newOpportunityArray" :key="index">
                    <td class="text-start field-number">{{opportunity.opportunity__opportunity_number}}</td>
                    <td class="text-start field-number">{{opportunity.opportunity__seu}}</td>
                    <td class="text-start field-text">{{opportunity.opportunity__climate_action_phase}}</td>
                    <td class="text-start field-description">{{truncatechars(opportunity.opportunity__description, 240)}}</td>
                    <td class="text-end field-number">
                        <span v-if="getSitePlanData().currency_details[1] === '$'">${{Math.round((opportunity.opportunity__savings_eur * getSitePlanData().currency_details[0])).toLocaleString()}}</span>
                        <span v-else>{{Math.round(opportunity.opportunity__savings_eur).toLocaleString()}}{{getSitePlanData().currency_details[1]}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="getSitePlanData().currency_details[1] === '$'">${{Math.round((opportunity.opportunity__total_investment_eur * getSitePlanData().currency_details[0])).toLocaleString()}}</span>
                        <span v-else>{{Math.round(opportunity.opportunity__total_investment_eur).toLocaleString()}}{{getSitePlanData().currency_details[1]}}</span>
                    </td>
                    <td class="text-end field-number">{{opportunity.opportunity__co2_tonnes.toLocaleString()}}</td>
                    <td class="text-end field-choice">
                      <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-2"></div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-3">
                          <select v-model="newStartDates[index][1]">
                            <option selected>{{ opportunity.start_month }}</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1">
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-5">

                          <select v-model="newStartDates[index][2]">
                            <option>{{ opportunity.start_year }}</option>
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                            <option>2025</option>
                            <option>2026</option>
                            <option>2027</option>
                            <option>2028</option>
                            <option>2029</option>
                            <option>2030</option>
                          </select>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1"></div>
                      </div>
                    </td>
                    <td class="text-end field-choice">
                      <div class="row plain-element">
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-2"></div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-3">
                          <select v-model="newStartDates[index][3]">
                            <option>{{ opportunity.end_month }}</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1">
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-5">
                          <select v-model="newStartDates[index][4]">
                            <option>{{ opportunity.end_year }}</option>
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                            <option>2025</option>
                            <option>2026</option>
                            <option>2027</option>
                            <option>2028</option>
                            <option>2029</option>
                            <option>2030</option>
                          </select>
                        </div>
                        <div class=" col col-12 col-sm-12 col-md-12 col-lg-1"></div>
                      </div>
                    </td>

                  </tr>
                </tbody>
              </table>

              <div class="row row-break"> </div>
              <div v-if="getOpportunities().length > 0" class="border-line"></div>
          <div v-if="resultCount && keywordFilteredList.length > 200" class="row plain-element">
            <button v-show="next" @click="submitQuery" class="btn btn-loading">
              Load More
            </button>
          </div>
          <div v-if="resultCount && keywordFilteredList.length === 0" class="row plain-element">
            <h6>No results match search criteria</h6>
          </div>
        </div>
        <div v-if="getOpportunities().length === 0" class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element">
          <p class="w-100"></p>
          <span class="span-info"> No Opportunities available</span>
        </div>
    </div>

</div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ReschedulePlanComponent",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    site_code: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    opportunityArray: {
      type: Array,
      required: true,
    },
    opportunityList: {
      type: Array,
      required: true,
    },
    opportunityDates: {
      type: Array,
      required: true,
    },
    co2_emission_tonnes: {
      type: Number,
      required: true,
    },
    cost_baseline: {
       type: Number,
       required: true,
    }
  },
  components: {
  },
  data() {
    return {
      newOpportunityArray: this.opportunityArray,
      seu: "",
      resultCount: null,
      search: "",
      opportunities: this.opportunityList,
      co2List : [],
      newStartDates: this.opportunityDates,
    }
  },
  methods: {
    ...mapGetters(["getFormError", "getOpportunities",  "getCompanyString","getResultCount", "getLoader","getSitePlanData",
                  "getInvestmentWaterfallChartData", "getInvestmentChartData", "getCarbonChartData",
                  "getCarbonWaterfallChartData", "getCostSavingsWaterfallChartData", "getCostSavingsChartData",
                  "getCarbonClimateActionPhaseWaterfallChartData"]),
    ...mapActions(["performSetFormError", "fetchOpportunitiesQueryset", "performUpdatePlanDates","performUpdateCharts"]),
    getOpportunityList(site) {
      this.fetchOpportunitiesQueryset({"searchQuery": site, "queryType": "site_code"});
    },
    updatePlanDates() {
        const errorOpportunities = [];
        for (const date of this.newStartDates) {
          const opportunityNumber = date[0];
          const firstDate = new Date(`${date[2]}-${date[1]}-01`);
          const secondDate = new Date(`${date[4]}-${date[3]}-01`);
          if (firstDate < secondDate) {
            continue;
          } else {
            console.log(`First date (${firstDate.toDateString()}) is not prior to the second date (${secondDate.toDateString()})`);
            errorOpportunities.push(opportunityNumber);
          }
        }
        if (errorOpportunities.length > 0) {
            var error = "Erratic date duration present in opportunities: " + errorOpportunities.join(', ');
            this.performSetFormError(error);
        } else {
              this.performUpdatePlanDates({
                "id": this.id,
                "dates_array": this.newStartDates,
              })
        }
    },
    //  Just in case if some strings would be too long and would destroy a layout
    truncatechars(value, limit) {
      if (value) {
        if (value.length > limit) {
          value = value.substring(0, limit) + "...";
          }
          return value
      }
    },
  },
  computed: {
    filteredList() {
      if (this.seu !== "") {
          return this.newOpportunityArray.filter(opportunity => {
            return opportunity.opportunity__seu.toLowerCase() === this.seu.toLowerCase()
          })
      } else {
        return this.newOpportunityArray
      }
    },
    keywordFilteredList() {
    return this.filteredList.filter(opportunity => {
      return opportunity.opportunity__site_code__site_code.toLowerCase().includes(this.search.toLowerCase()) ||
             opportunity.opportunity__seu.toLowerCase().includes(this.search.toLowerCase()) ||
             opportunity.opportunity__description.toLowerCase().includes(this.search.toLowerCase())
    })
    },
    chartStyles () {
      return {
        height: `100%`,
        position: "relative"
      }
    }

  },
  created() {
    this.getOpportunityList(this.site_code);
    this.performSetFormError(null);
    document.title = "Reschedule Plan";
    window.scrollTo(0,0);
  },
  beforeRouteUpdate (to, from, next) {
    this.chosenSite = to.params.site_code;
    this.getOpportunityList(this.chosenSite);
    next();
  }

}
</script>
