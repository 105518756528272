<template>
 <div class="row plain-element">
    <div v-if="opportunities.length > 0" class="row plain-element">
        <div class=" col col-12 col-sm-12 col-md-12 col-lg-12 plain-element text-start">
            <p class="w-100"></p>
            <div class="row plain-element">
                <div v-if="site_seus" class=" col col-12 col-sm-12 col-md-3 col-lg-2 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
                        <div class="row plain-element text-start">
                            <label class="label-search">Site </label>
                        </div>
                        <select v-model="site" name="site" id="site" @change="clearSeu()">
                            <option value="" selected> All Sites</option>
                            <option v-for="(key, value, index) in site_seus" :key="index" :value="value">
                                {{ value }} - {{siteNames[value]}}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="site_seus" class=" col col-12 col-sm-12 col-md-3 col-lg-2 plain-element">
                    <div class="input-field  col col-12 col-sm-8 col-form">
                        <div class="row plain-element text-start">
                            <label class="label-search">SEU</label>
                        </div>
                        <select v-model="seu" name="seu" id="seu" @change="clearPhase()">
                            <option value="" selected> All SEU</option>
                            <option v-for="(element, index) in site_seus[site]" :key="index">
                                {{ element }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="site_seus" class=" col col-12 col-sm-12 col-md-3 col-lg-2 plain-element">
                    <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
                        <div class="row plain-element text-start">
                            <label class="label-search">Climate Action Phase</label>
                        </div>
                        <select v-model="climate_action_phase" name="climate_action_phase" id="climate_action_phase">
                            <option value="" selected> All Phases</option>
                            <option value="1 - Low/No Cost" selected> 1 - Low/No Cost</option>
                            <option value="2 - Energy Efficiency" selected> 2 - Energy Efficiency</option>
                            <option value="3 - Renewable Electricity" selected> 3 - Renewable Electricity</option>
                            <option value="4 - Electrification of Heat" selected> 4 - Electrification of Heat</option>
                            <option value="5 - Renewable Fuels" selected> 5 - Renewable Fuels</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row plain-element">
                <div class="col col-sm-3 col-md-3 col-lg-3 plain-element text-start">
                    <h6 class="font-cyan-blue font-12"> {{ filteredList.length }} Opportunities</h6>
                </div>
            </div>
            <p class="w-100"></p>
            <table id="searchTable" data-sort-dir="down">
                <thead>
                <tr>
                    <th class="text-start" @click="sortTableNew(0)">
                        Opportunity No.
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-start" @click="sortTableNew(1)">Site
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-start" @click="sortTableNew(2)">SEU
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-start" @click="sortTableNew(3)">Climate Action Phase
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-center th-description">Description</th>
                    <th class="text-center th-description">Duration</th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(6)">
                        <span>Savings({{currency_details[1]}})</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(7)">
                        <span>Total Inv.({{currency_details[1]}})</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(8)">
                        <span>CO2(T)</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(9)">
                        <span>CO2/1k{{currency_details[1]}}(T)</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(10)">
                        <span>MWHE</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(11)">
                        <span>MWHT</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                    <th class="text-end" @click="sortOppTableCurrencyInt(12)">
                        <span>Payback(Y)</span>
                        <span class="fa fa-stack">
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                        <i class="fa fa-caret-up" aria-hidden="true"></i>
                    </span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="opportunity in filteredList" :key="opportunity.opportunity__id" @click="goToOpportunityView(opportunity.opportunity__id.toString())">
                    <td class="text-start field-text">{{opportunity.opportunity__opportunity_number}}</td>
                    <td class="text-start field-number">{{siteNames[opportunity.opportunity__site_code__site_code]}}</td>
                    <td class="text-start field-number">{{opportunity.opportunity__seu}}</td>
                    <td class="text-start field-text">{{opportunity.opportunity__climate_action_phase}}</td>
                    <td class="text-start field-description" id="field-description-22">{{truncatechars(opportunity.opportunity__description,
                        240)}}
                    </td>
                    <td class="text-center field-choice">
                        <span v-if="opportunity.start_year !== opportunity.end_year">{{opportunity.start_year}} - {{opportunity.end_year}}</span>
                        <span v-else>{{opportunity.start_year}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="currency_details[1] === '$'">${{Math.round((opportunity.opportunity__savings_eur * currency_details[0])).toLocaleString()}}</span>
                        <span v-else>{{Math.round(opportunity.opportunity__savings_eur).toLocaleString()}}{{currency_details[1]}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="currency === '$'">${{Math.round((opportunity.opportunity__total_investment_eur * currency_details[0])).toLocaleString()}}</span>
                        <span v-else>{{Math.round(opportunity.opportunity__total_investment_eur).toLocaleString()}}{{currency_details[1]}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="opportunity.opportunity__co2_tonnes > 999">{{Math.round(opportunity.opportunity__mwhe).toLocaleString()}}</span>
                        <span v-else>{{opportunity.opportunity__mwhe.toLocaleString()}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span>{{ carbonPerEuroDict[opportunity.opportunity__opportunity_number]}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="opportunity.opportunity__mwhe > 999">{{Math.round(opportunity.opportunity__mwhe).toLocaleString()}}</span>
                        <span v-else>{{opportunity.opportunity__mwhe.toLocaleString()}}</span>
                    </td>
                    <td class="text-end field-number">
                        <span v-if="opportunity.opportunity__mwht > 999">{{Math.round(opportunity.opportunity__mwht).toLocaleString()}}</span>
                        <span v-else>{{opportunity.opportunity__mwht.toLocaleString()}}</span>
                    </td>
                    <td class="text-end field-number">{{opportunity.opportunity__payback_yrs}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>

import { mapGetters } from "vuex";
import { truncatechars } from "@/common/general.js";
import {  sortTableNew, sortOppTableCurrencyInt } from "@/common/tables.js";

export default {
  name: "DivisionPlanOpportunitiesComponent",
  props: {
    opportunities: {
      type: Array,
      required: true,
    },
    site_seus: {
      type: Object,
      required: true,
    },
    siteNames: {
      type: Object,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    currency_details: {
      type: Array,
      required: true,     
    },
    carbonPerEuroDict: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    }

  },
  components: {

  },
  data() {
    return {
      climate_action_phase : "",
      seu : "",
      site: "",
    }
  },
  methods: {
    ...mapGetters(["getUsername"]),
    truncatechars: truncatechars,
    sortTableNew: sortTableNew,
    sortOppTableCurrencyInt: sortOppTableCurrencyInt,
    clearSeu() {
        if (this.site === "") {
          this.seu = ""
          this.climate_action_phase = ""
        } else {
          this.seu = ""
          this.climate_action_phase = ""
        }
    },
    clearPhase() {
       this.climate_action_phase = "";
    },
    calculateTotalInvestmentSum(opportunitiesObject) {
      let totalSum = 0;

      // Iterate through the opportunities object
      for (const opportunityKey in opportunitiesObject) {
        if (Object.prototype.hasOwnProperty.call(opportunitiesObject, opportunityKey)) {
          const opportunity = opportunitiesObject[opportunityKey];
          const investment = opportunity.opportunity__total_investment_eur || 0;

          // Add the investment value to the total sum
          totalSum += investment;
        }
      }

      return totalSum;
    },
    goToOpportunityView(id) {
      if (event.ctrlKey === true) {
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      } else {
//        this.$router.push({ name: 'opportunity-view', params: { id: id } });
        let routeData = this.$router.resolve({ name: 'opportunity-view', params: { id: id } });
        window.open(routeData.href, '_blank');
      }
    },
  },

  computed: {
      filteredList() {
        if (this.site !== "" && this.seu !== "" && this.climate_action_phase !== "" ) {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.opportunity__seu.toLowerCase().includes(this.seu.toLowerCase()) &&
                      opportunity.opportunity__climate_action_phase.toLowerCase() === this.climate_action_phase.toLowerCase()
            })
        } else if (this.site !== "" && this.seu !== ""  && this.climate_action_phase === "" ) {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.opportunity__seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.site !== ""  && this.seu === "" && this.climate_action_phase === "") {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__site_code__site_code.toLowerCase() === this.site.toLowerCase()
            })
        } else if (this.site !== ""  && this.seu !== "" && this.climate_action_phase === "") {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                     opportunity.opportunity__seu.toLowerCase().includes(this.seu.toLowerCase())
            })
        } else if (this.site !== "" && this.seu === "" && this.climate_action_phase !== "") {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__site_code__site_code.toLowerCase() === this.site.toLowerCase() &&
                      opportunity.opportunity__climate_action_phase.toLowerCase() === this.climate_action_phase.toLowerCase()
            })
        }  else if (this.site === "" && this.seu === "" && this.climate_action_phase !== "") {
            return this.opportunities.filter(opportunity => {
              return opportunity.opportunity__climate_action_phase.toLowerCase() === this.climate_action_phase.toLowerCase()
            })
        } else {

          return this.opportunities
        }
    },
  },
  created() {


  },
  beforeRouteUpdate (to, from, next) {

    next();
  }

}
</script>
