<template>
<div class="row plain-element">
<p class="w-100"></p>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="row plain-element text-start">
              <h6>CO2 Reduction per Climate Action Phase(Tonnes):</h6>
            </div>
            <div class="plan-chart">
              <carbon-climate-action-phase-waterfall-graph :chart-data="carbonClimateActionPhaseWaterfallChartData"></carbon-climate-action-phase-waterfall-graph>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-4">
                <h6>Annual CO2 Emission(Tonnes):</h6>
              </div>
              <div v-if="isSumGreaterThanZero(divisionPlanCarbonTracker)" class="col col-12 col-sm-2 col-md-4 col-lg-4 text-center" id="div-padding-top-6">
                <div class="legend-square"></div>
                <div id="climate-action-block"> Actual CO2 Tonnes </div>
              </div>
              <div v-else class="col col-12 col-sm-2 col-md-4 col-lg-4 text-center">
              </div>
              <div class=" col col-12 col-sm-12 col-md-12 col-lg-4 text-end">
                <router-link  v-if="plan_manager ===  getUsername()" :to="{name: 'division-plan-carbon-tracker', params: {id: id}}" class="btn btn-reorder">CO2 Tracker</router-link>
              </div>
            </div>
            <div v-if="carbonWaterfallChartData" class="plan-chart">
              <carbon-waterfall-graph :chart-data="carbonWaterfallChartData"></carbon-waterfall-graph>
            </div>
          </div>
        </div>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
            <div class=" col col-12 col-sm-12 col-md-3 col-lg-3 plain-element">
                <div class="input-field  col col-12 col-sm-12 col-md-8 col-form">
                    <div class="row plain-element text-start">
                        <label class="label-search">Site</label>
                    </div>
                    <select id="site_code" @change="switchSiteClimateWaterfall">
                        <option v-for="(element, index) in siteNames" :key="index" :value="element.site_code">
                          {{ element.site_code }} - {{ element.site_name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Site CO2 Reduction per Climate Action Phase(Tonnes):</h6>
            </div>
            <div v-if="getSiteSpecificClimateWaterfall()" class="plan-chart">
              <site-climate-phase-graph :chart-data="getSiteSpecificClimateWaterfall()"></site-climate-phase-graph>
            </div>
          </div>
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <h6>Site Annual CO2 Emission(Tonnes):</h6>
            </div>
            <div v-if="getSiteSpecificAnnualWaterfall()" class="plan-chart">
              <site-carbon-waterfall-graph :chart-data="getSiteSpecificAnnualWaterfall()"></site-carbon-waterfall-graph>
            </div>
          </div>

        </div>


        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-2 col-md-1 col-lg-1 text-start"></div>
          <div class=" col col-12 col-sm-2 col-md-2 col-lg-2 text-start" v-for="key,value in climateActionLegend" :key="value">
            <div class="legend-square" :style="'background-color:' + key"> </div>
            <div id="climate-action-block">
              {{ value }}
            </div>
          </div>
        </div>

        <div v-if="opportunity_plan_relations_length > 0" class="row plain-element">
          <div class=" col col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="row plain-element text-start">
              <h6>CO2 Reduction By Opportunity(Tonnes):</h6>
            </div>
            <div v-if="carbonChartData" class="plan-chart">
              <carbon-graph :chart-data="carbonChartData" id="chart-500"></carbon-graph>
            </div>
          </div>
        </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CarbonGraph from "@/common/graphs/CarbonGraph.js";
import CarbonWaterfallGraph from "@/common/graphs/CarbonWaterfallGraph.js";
import CarbonClimateActionPhaseWaterfallGraph from "@/common/graphs/CarbonClimateActionPhaseWaterfallGraph.js";
import SiteClimatePhaseGraph from "@/common/graphs/SiteClimatePhaseGraph.js";
import SiteCarbonWaterfallGraph from "@/common/graphs/SiteCarbonWaterfallGraph.js";

export default {
  name: "DivisionPlanClimateGraphsComponent",
  props: {
    opportunity_plan_relations_length: {
      type: Number,
      required: true,
    },
    carbonClimateActionPhaseWaterfallChartData: {
      type: Object,
      required: true,
    },
    carbonWaterfallChartData: {
      type: Object,
      required: true,
    },
    carbonChartData: {
      type: Object,
      required: true,
    },
    site_carbon_reduction_per_climate_action_phase: {
      type: Object,
      required: true,
    },
    site_total_carbon_chart_data: {
      type: Object,
      required: true,
    },
    divisionPlanCarbonTracker: {
      type: Object,
      required: true,
    },
    siteNames: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    plan_manager: {
      type: String,
      required: true,
    },
  },
  components: {
    CarbonGraph,
    CarbonWaterfallGraph,
    CarbonClimateActionPhaseWaterfallGraph,
    SiteClimatePhaseGraph,
    SiteCarbonWaterfallGraph
  },
  data() {
    return {
      climateActionLegend: {
        "1 - Low/No Cost": "#ff9d9a",
        "2 - Energy Efficiency": "#86bcb6",
        "3 - Renewable Electricity": "#f1ce63",
        "4 - Electrification of Heat": "#a0cbe8",
        "5 - Renewable Fuels": "#d4a6c8",
      },
    }
  },
  methods: {
    ...mapGetters(["getUsername", "getSiteSpecificClimateWaterfall", "getSiteSpecificAnnualWaterfall"]),
    ...mapActions(["performSetSiteSpecificClimateWaterfall", "performSetSiteSpecificAnnualWaterfall"]),
    isSumGreaterThanZero(obj) {
      let sum = 0;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          sum += obj[key];
        }
      }
      return sum > 0;
    },
    switchSite(event) {
        const selectedValue = event.target.value;
        console.log("Selected value:", selectedValue);

    },
    switchSiteClimateWaterfall(event) {
        const site = event.target.value;
        var climateData = {
                    labels: this.site_carbon_reduction_per_climate_action_phase[site][0],
                    datasets:  [{
                                backgroundColor: this.site_carbon_reduction_per_climate_action_phase[site][2],
                                data: this.site_carbon_reduction_per_climate_action_phase[site][1],
                                tooltips: this.site_carbon_reduction_per_climate_action_phase[site][5],
                              }]
                    };
        this.performSetSiteSpecificClimateWaterfall(climateData);

        var annualData = {
                    labels: this.site_total_carbon_chart_data[site][0],
                    datasets:  [{
                                backgroundColor: this.site_total_carbon_chart_data[site][2],
                                data: this.site_total_carbon_chart_data[site][1],
                                tooltips: this.site_total_carbon_chart_data[site][5],
                              }]
        }
        this.performSetSiteSpecificAnnualWaterfall(annualData);
    },
  },

  computed: {


  },
  created() {


  },
}
</script>
